<template>
  <div class="w-full md:hidden">
    <span>Image | Convert</span>
  </div>
  <div class="flex flex-wrap border border-black">
    <div class="w-full md:w-1/3 p-2 md:p-4">
      <p class="border-b border-black font-bold mb-4">1. Input</p>
      <p>JPG, PNG, GIF, HEIC, HEIF</p>
      <label class="inline-block w-full text-white font-bold p-2 bg-blue-600 cursor-pointer mt-2 text-center">
        Add file(s)
        <input @change="add" type="file" class="hidden"
               accept="image/jpeg, image/png, image/gif, image/heic, image/heif"
               multiple>
      </label>
    </div>
    <div class="w-full md:w-1/3 border-l border-r border-black p-2 md:p-4">
      <p class="border-b border-black font-bold mb-4">2. Compression</p>
      <p>Select compression (Quality {{ quality * 100 }}%)</p>
      <div class="w-full flex flex-wrap">
        <div @click="setCompression(1)" :class="getCompressionClass(1)" class="w-1/4 text-white select-none font-bold p-2 mt-2 text-center cursor-pointer">
          None
        </div>
        <div @click="setCompression(0.9)" :class="getCompressionClass(0.9)" class="w-1/4 text-white select-none font-bold p-2 mt-2 text-center cursor-pointer">
          Low
        </div>
        <div @click="setCompression(0.6)" :class="getCompressionClass(0.6)" class="w-1/4 text-white select-none font-bold p-2 mt-2 text-center cursor-pointer">
          Medium
        </div>
        <div @click="setCompression(0.3)" :class="getCompressionClass(0.3)" class="w-1/4 text-white select-none font-bold p-2 mt-2 text-center cursor-pointer">
          High
        </div>
      </div>
    </div>
    <div class="w-full md:w-1/3 p-2 md:p-4">
      <p class="border-b border-black font-bold mb-4">3. Output</p>
      <p>JPG</p>
      <div v-if="converted_files.size < 1" @click="convert" class="text-white font-bold p-2 bg-blue-600 mt-2 cursor-pointer text-center">Convert</div>
      <div v-else>
        <div v-if="progress === 100" @click="downloadAll" class="text-white font-bold p-2 bg-blue-600 mt-2 cursor-pointer text-center">Download all as ZIP</div>
        <div v-else class="text-white font-bold p-2 bg-gray-600 mt-2 text-center">Download all as ZIP</div>
      </div>
    </div>
  </div>
  <div class="py-4">
    <div class="w-full p-2 md:p-4 text-center font-bold">
      <p v-if="!progress && original_files.size < 1">Add file(s) for conversion</p>
      <p v-if="original_files.size > 0 && !progress">Ready to convert</p>
      <p v-if="progress && progress < 100">Progress: {{ progress }}%</p>
      <p v-if="progress && progress === 100">Done!</p>
    </div>
  </div>
  <div class="flex flex-wrap  border-t border-black">
    <div v-for="[id, file] in original_files.entries()" class="w-full flex flex-wrap p-4 border-b border-black">
      <div class="w-full flex flex-wrap">
        <div class="w-full md:w-1/5">Original:</div>
        <div class="w-full md:w-1/5">{{ file.name }}</div>
        <div class="w-full md:w-1/5">{{ bytesToKb(file.size) }} kb</div>
        <div class="w-full md:w-1/5">{{ file.type }}</div>
        <div class="w-full md:w-1/5">
          <div v-if="!progress" @click="remove()" class="text-white font-bold bg-blue-600 text-center">Remove</div>
        </div>
      </div>
      <div v-if="converted_files.get(id)" class="w-full flex flex-wrap mt-1">
        <div class="w-full md:w-1/5">Converted:</div>
        <div class="w-full md:w-1/5">{{ converted_files.get(id).name }}</div>
        <div class="w-full md:w-1/5"><span v-if="converted_files.get(id) !== 'unknown_format'">{{ bytesToKb(converted_files.get(id).size) }} kb</span></div>
        <div class="w-full md:w-1/5">{{ converted_files.get(id).type }}</div>
        <div class="w-full md:w-1/5">
          <div v-if="converted_files.get(id) === 'unknown_format'" class="text-center">Unsupported format</div>
          <div v-else @click="downloadOne(id)" class="text-white font-bold bg-blue-600 cursor-pointer text-center">Download</div>
        </div>
      </div>
    </div>
  </div>
  <div class="h-14"></div>
</template>

<script>

import heic2any from "heic2any"
import {compress, compressAccurately} from 'image-conversion';
import { downloadZip } from 'client-zip'
import path from 'path-browserify'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'Home',
  data() {
    return {
      original_files: new Map(),
      converted_files: new Map(),
      output_type: 'image/jpeg',
      output_extension: '.jpeg',
      progress: null,
      quality: 0.9
    }
  },
  methods: {
    add(event) {
      this.original_files.clear()
      this.converted_files.clear()
      this.progress = null
      for (let file of event.target.files) {
        this.original_files.set(uuidv4(), file)
      }
    },
    async convert() {
      this.progress = 1
      let noOfFiles = this.original_files.size
      for (let [id, file] of this.original_files.entries()) {
        let name = path.parse(file.name).name
        const buffer = await this.readFileAsync(file)
        const blob = new Blob([buffer])
        if (file.type === 'image/heic' || file.type === 'image/heif') {
          let converted = await heic2any({ blob, quality: this.quality, toType: this.output_type })
          converted.name = name + this.output_extension
          this.converted_files.set(id, converted)
        } else if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif') {
          let converted = await compress(blob, {quality: this.quality, type: this.output_type})
          let newBlob = new Blob([converted], {name: converted.name, type: this.output_type})
          newBlob.name = name + this.output_extension
          this.converted_files.set(id, newBlob)
        } else {
          this.converted_files.set(id, 'unknown_format')
        }

        this.progress = Math.round((this.converted_files.size / noOfFiles) * 100)
      }
    },
    downloadOne(id) {
      let file = this.converted_files.get(id)
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = file.name;
      link.click();
      URL.revokeObjectURL(link.href);
    },
    async downloadAll() {
      let files = []
      for (let [id, file] of this.converted_files.entries()) {
        if (file !== 'unknown_format') {
          files.push(new File([file], file.name, { type: "image/jpeg"}))
        }
      }
      const blob = await downloadZip(files).blob()
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = `cftb-image-convert-${Math.round(Date.now()/1000)}.zip`
      link.click()
      link.remove()
      URL.revokeObjectURL(link.href);
    },
    readFileAsync(file) {
      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsArrayBuffer(file);
      })
    },
    bytesToKb(bytes) {
      return Math.round(bytes / 1000)
    },
    setCompression(value) {
      this.quality = value
    },
    getCompressionClass(value) {
      if (value === this.quality) {
        return "bg-blue-600"
      } else {
        return "bg-gray-600"
      }
    }
  }
}

</script>